import { AccountManager } from "app/services/permissions/account-manager";

export class ReportLink {
    label: string;
    displayLabel?: string = this.label;
    link: string;
    visible: (AccountManager, string) => boolean;
}

export class ReportList {


    public static getReportLinks(accountManager: AccountManager, agentId: string): Array<ReportLink> {
        const routeLinks = [
            {
                label: "app.reports.report-list.casino-performance", link: "casinoperformance",
                visible: () => accountManager.CanViewCasinoPerformance(agentId)
            },
            {
                label: "app.reports.report-list.player-game", link: "playergame",
                visible: () => accountManager.canViewPlayerGame(agentId)
            },
            {
                label: "app.reports.report-list.player-performance", link: "playerperformance",
                visible: () => accountManager.CanViewPlayerPerformance(agentId)
            },
            {
                label: "app.reports.report-list.credit-transfer-report", link: "credittransferlist",
                visible: () => accountManager.canViewCreditTransferReport(agentId)
            },
            {
                label: "app.reports.report-list.agent-summary", link: "agentsummary",
                visible: () => accountManager.canViewAgentSummaryReport(agentId)
            },
            {
                label: "app.reports.report-list.cafe-summary", link: "cafesummary",
                visible: () => accountManager.canViewCafeSummaryReport(agentId)
            },
            {
                label: "app.reports.report-list.game-activity", link: "gameactivity",
                visible: () => accountManager.canViewGameActivityReport(agentId)
            },
            {
                label: "app.reports.report-list.game-performance-ngp", link: "gameperformancengp",
                visible: () => accountManager.canViewGamePerformanceReport(agentId)
            },
            {
                label: "app.reports.report-list.player-sessions", link: "playersessions",
                visible: () => accountManager.canViewPlayerSessionsReport(agentId)
            },
            {
                label: "app.reports.report-list.balance-adjustments", link: "balanceadjustments",
                visible: () => accountManager.canViewBalanceAdjustmentsReport(agentId)
            },
            {
                label: "app.shared.labels.side-bet", link: "sidebets",
                visible: () => accountManager.canViewSideBetsReport(agentId)
            },
            {
                label: "app.reports.routing.title-optin", link: "optin",
                visible: () => accountManager.canViewOptInReport(agentId)
            },
            {
                label: "app.reports.routing.title-jackpot-history", link: "jackpothistory",
                visible: () => accountManager.canViewJackpotHistory(agentId)
            },
            {
                label: "app.reports.report-list.player-pending-game", link: "pendinggames",
                visible: () => accountManager.canViewPendingGamesReport(agentId)
            }

        ];
        return routeLinks.filter(l => l.visible());
    }

}
