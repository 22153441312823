import { FormatHelper } from "app/shared/utils/format-helper";

/*
 *  Decimals to display for JP Contribution column report:
 *      - Default decimals to display for every currency its 6.
 *      - Decimals to display for MBT JP contribution reports its 8.
 */

const DEFAULT_DECIMALS_TO_DISPLAY = 6;
const MBT_DECIMALS_TO_DISPLAY = 8;

export class Formatters {
    public static shortDate(value: any, column?: any, row?: any, language: string = navigator.language): string {
        return FormatHelper.shortDate(value, language);
    }
    public static shortDateAndTime(value: any, column?: any, row?: any, language: string = navigator.language): string {
        return FormatHelper.shortDateAndTime(value, language);
    }
    public static currency(value: any, column?: any, row?: any, language: string = navigator.language, currencyClassification?: string, decimalPrecision:number = 2): string {
        return FormatHelper.currency(value, row["currency"], language, currencyClassification, decimalPrecision);
    }

    public static jackpotContribution(value: any, column?: any, row?: any, language: string = navigator.language): string {
        const decimalsToDisplay = (row["currency"] === 'MBT') ? MBT_DECIMALS_TO_DISPLAY : DEFAULT_DECIMALS_TO_DISPLAY;
        return FormatHelper.currency(value, row["currency"], language, row.currencyClassification, decimalsToDisplay);
    }

    public static round(value: any, column?: any, row?: any): string {
        return FormatHelper.round(value);
    }
    public static percentage(value: any, column?: any, row?: any): string {
        return FormatHelper.percentage(value);
    }
    public static targetName(value: any, column?: any, row?: any): string {
        return `
        <div style="display: flex";>
            <i class="fa fa-lg fa-fw ${row["targetEntityType"] === "P" ? "as-icon-Pos" : "as-icon-Agents"}"></i>
            <div style="margin-left: 5px;padding-top:4px;">${value}</div>
        </div>`;
    }
    public static textTooltip(value: any): string {
        return value;
    }

    public static sourceBalance(value: any, column?: any, row?: any,
        language: string = navigator.language, translations: {creditSource?} = {}, currencyClassification?: string): string {
        if (row["sourceIsCreditSource"]) {
            return translations.creditSource;
        } else {
            return FormatHelper.currency(value, row["currency"], language, currencyClassification);
        }
    }
}


export const ShortDateFormatter = Formatters.shortDate;
export const CurrencyFormatter = Formatters.currency;
export const ShortDateAndTimeFormatter = Formatters.shortDateAndTime;
export const RoundFormatter = Formatters.round;
export const TargetName = Formatters.targetName;
export const TextTooltip = Formatters.textTooltip;
export const SourceBalance = Formatters.sourceBalance;
export const PercentageFormatter = Formatters.percentage;
export const JackpotContributionFormatter = Formatters.jackpotContribution;
